import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { Heading, InlineTextButton } from '../../components';
import { richText } from '../../util/richText';

import { getStoredLanguage, detectLanguage } from '../../util/translations.js';
import translateWeglot from '../../translations/useWeglot.js';
import { calculatePostLanguage } from '../../components/TranslateButton/TranslateButton.js';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const createContent = text =>
  richText(text, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

/**
 * SectionTextMaybe:
 * - Displays a listing description (passed in as `text`).
 * - Automatically translates the text when the global language (intl.locale) changes.
 * - Provides a manual "Translate/Show original" button as a fallback.
 */
const SectionTextMaybe = props => {
  const { text, heading, currentAuthor, showAsIngress = false } = props;
  const intl = useIntl();
  const textClass = showAsIngress ? css.ingress : css.text;

  const [isTranslated, setIsTranslated] = useState(false);
  const [translationFailed, setTranslationFailed] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [translatedRichText, setTranslatedRichText] = useState('');

  // Effect 1: When text changes, set original content and decide if translation is needed
  useEffect(() => {
    if (text && text.trim().length > 0) {
      const originalContent = createContent(text);
      setTranslatedRichText(originalContent);

      detectLanguage(text)
        .then(langCode => {
          if (!langCode) {
            langCode = calculatePostLanguage(currentAuthor);
          }
          const userLang = getStoredLanguage();
          setShowButton(langCode !== userLang);
        })
        .catch(err => {
          console.error('Language detection failed:', err);
          setShowButton(true);
        });
    } else {
      setTranslatedRichText('');
    }
    setIsTranslated(false);
    setTranslationFailed(false);
  }, [text, currentAuthor]);

  // Effect 2: Auto translate when the global language (intl.locale) changes
  useEffect(() => {
    if (text && text.trim().length > 0) {
      detectLanguage(text)
        .then(sourceLang => {
          const userLang = getStoredLanguage();
          if (sourceLang && sourceLang !== userLang) {
            translateWeglot(text, sourceLang, userLang)
              .then(translated => {
                if (translated) {
                  setIsTranslated(true);
                  setTranslationFailed(false);
                  setTranslatedRichText(createContent(translated));
                } else {
                  setIsTranslated(false);
                  setTranslatedRichText(createContent(text));
                }
              })
              .catch(err => {
                console.error('Auto translation error:', err);
                setIsTranslated(false);
                setTranslationFailed(true);
                setTranslatedRichText(createContent(text));
              });
          } else {
            setIsTranslated(false);
            setTranslatedRichText(createContent(text));
          }
        })
        .catch(err => {
          console.error('Language detection error on locale change:', err);
          setTranslatedRichText(createContent(text));
        });
    }
  }, [intl.locale, text, currentAuthor]);

  // Manual translation toggle
  const handleTranslate = async () => {
    if (!text || text.trim().length === 0) return;

    if (!isTranslated) {
      try {
        const sourceLang = await detectLanguage(text);
        const userLang = getStoredLanguage();
        const fallbackLang = calculatePostLanguage(currentAuthor);
        const finalLang = sourceLang || fallbackLang;

        if (!finalLang) {
          console.log('No valid sourceLang found. Aborting translation.');
          return;
        }

        const translated = await translateWeglot(text, finalLang, userLang);
        if (translated) {
          setIsTranslated(true);
          setTranslationFailed(false);
          setTranslatedRichText(createContent(translated));
        } else {
          console.log('Translation failed');
          setTranslationFailed(true);
        }
      } catch (err) {
        console.log('Translation error:', err);
        setTranslationFailed(true);
      }
    } else {
      // Revert to original text
      setIsTranslated(false);
      setTranslationFailed(false);
      setTranslatedRichText(createContent(text));
    }
  };

  const hasText = text && text.trim().length > 0;
  if (!hasText) {
    return (
      <section className={css.sectionText}>
        {heading && (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        )}
        <p className={css.noDescriptionText}>
          <FormattedMessage id="ListingPage.noDescriptionFallback" />
        </p>
      </section>
    );
  }

  return (
    <section className={css.sectionText}>
      {heading && (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      )}
      <p className={textClass}>{translatedRichText}</p>
      {showButton && (
        <div className={css.translateButtonDiv}>
          <InlineTextButton className={css.translateButton} onClick={handleTranslate}>
            <FormattedMessage
              id={
                translationFailed
                  ? 'ListingPage.translationFailed'
                  : isTranslated
                  ? 'ListingPage.showOriginal'
                  : 'ListingPage.translate'
              }
            />
          </InlineTextButton>
        </div>
      )}
    </section>
  );
};

export default SectionTextMaybe;
